define("discourse/plugins/discourse-heti/discourse/initializers/initialize-heti", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "initialize-heti",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        api.decorateCooked($elem => {
          if (!$elem.hasClass("heti")) {
            $elem.addClass("heti");
          }
          if ($elem.hasClass("d-editor-preview")) {
            /* eslint-disable no-undef */
            new Heti(".d-editor-preview").autoSpacing();
          } else {
            const randomClass = "heti-" + Date.now();
            $elem.addClass(randomClass);
            /* eslint-disable no-undef */
            new Heti("." + randomClass).autoSpacing();
          }
        });
      });
    }
  };
});